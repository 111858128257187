<template>
  <div class="content-box">
    <el-dialog
      title="客户端发布入口"
      :visible="is_show"
      width="500px"
      class="dialog-vertical"
      @close="close()"
    >
    <el-form
      v-loading="loading"
      :model="form"
      label-width="60px"
      size="medium"
      @submit.native.prevent
    >
      <el-form-item label="二维码">
        <div class="code-box">
          <el-image fit="contain" :src="form.code"></el-image>
        </div>
        <el-button type="text">
          <a
            style="text-decoration: none"
            class="btn-hover"
            :href="form.code"
            download
            >下载二维码</a
          >
        </el-button>
      </el-form-item>
    </el-form>
      <span slot="footer">
        <el-button @click="close()" size="small">取消</el-button>
        <el-button
          type="primary"
          :loading="loading"
          size="small"
          @click="submit()"
          >确定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: "EntranceDialog",
  model: {
    prop: "open",
    event: "changeOpen",
  },
  props: {
    // 弹窗配置
    open: {
      type: Boolean,
      detault: false,
    },
  },
  components: {},
  created() {},
  computed: {
    // 显示框
    is_show: {
      get() {
        return this.$props.open;
      },
      set(val) {
        this.$emit("changeOpen", val);
      },
    },
  },
  watch: {},

  data() {
    return {
      form: {
        code:''
      },
      rules: {},
      loading:false
    };
  },
  methods: {
    close() {
      this.is_show = false;
    },
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.close();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
      this.close();
    },
  },
};
</script>
<style lang="scss" scoped></style>
