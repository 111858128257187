<template>
  <el-dialog :title="ImgText.id?'编辑图文':'发布图文'" :visible.sync="openDialog" width="600px" :show-close="true"
    class="dialog-vertical" @close="cancel('ImgText')">
    <el-form size="small" :model="ImgText" :rules="rules" ref="ImgText" label-width="85px" @submit.native.prevent>
      <el-form-item style="margin-bottom: 32px" label="文字内容：" prop="content">
        <el-input type="textarea" v-model="ImgText.content" :autosize="{ minRows: 6 }" style="width: 90%"
          placeholder="请填写文字内容">
        </el-input>
      </el-form-item>
      <el-form-item style="margin-bottom: 32px" label="类型：">
        <el-radio v-model="upLoadType" :label="0">图片</el-radio>
        <el-radio v-model="upLoadType" :label="1">视频</el-radio>
      </el-form-item>
      <el-form-item v-if="!upLoadType" label="上传：" prop="images">
        <media-wall v-model="ImgText.images" :width="135" :height="94" :limit="9 - ImgText.images.length"
          :UploadType="['image']" :draggable="true">
        </media-wall>
        <el-input v-model="ImgText.images.join(',')" v-show="false" />
        <div class="form-tip">
          <span class="el-icon-info" style="font-size: 15px;color:#C0C4CC"></span>
          <span>拖动图片可以对其排序</span>
        </div>
      </el-form-item>
      <el-form-item v-else label="上传：" prop="video">
        <media-wall v-model="ImgText.video" :width="135" :height="94" UploadType="video"
          :limit="9 - ImgText.video.length" addText="上传视频" :draggable="true"></media-wall>
        <el-input v-model="ImgText.video.join(',')" v-show="false" />
      </el-form-item>
    </el-form>
    <span slot="footer">
      <el-button @click="openDialog = false">取消</el-button>
      <el-button type="primary" @click="submit('ImgText')" :loading="saveLoading">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { SaveGraphic } from "../../api/activity-list/graphic";
import MediaWall from "@/modules/common/components/MediaWall.vue";
export default {
  model: {
    prop: "open",
    event: "closeDialog",
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    GraphicForm: {
      type: Object,
    },
    updateList: {
      type: Function,
    },
  },
  data() {
    return {
      // 图文表单
      ImgText: {
        id: 0,
        activity_id: 0,
        content: "",
        images: [],
        video: [],
      },
      upLoadType: 0,
      rules: {
        content: [{ validator: this.contentPass, trigger: "blur" }],
        images: [{ validator: this.imagesPass, trigger: "change" }],
        video: [{ validator: this.videoPass, trigger: "change" }],
      },
      saveLoading: false,
    };
  },
  methods: {
    //验证文字内容
    contentPass (rule, value, callback)  {
      if (value) {
        if (
          (!this.upLoadType && !this.ImgText.images.length) ||
          (this.upLoadType && !this.ImgText.video.length)
        ) {
          this.$refs.ImgText.validateField(["images", "video"]);
          callback();
        } else {
          callback();
        }
      } else {
        if (
          (!this.upLoadType && !this.ImgText.images.length) ||
          (this.upLoadType && !this.ImgText.video.length)
        ) {
          callback(new Error("请填写文字内容"));
        } else {
          callback();
        }
      }
    },
    //验证图片上传
    imagesPass(rule, value, callback) {
      if (value.length) {
        this.$refs.ImgText.validateField("content");
        callback();
      } else {
        if (this.ImgText.content) {
          callback();
        } else {
          callback(new Error("请填上传图片"));
          this.$refs.ImgText.validateField("content");
        }
      }
    },
    //验证视频上传
    videoPass(rule, value, callback) {
      if (value.length) {
        this.$refs.ImgText.validateField("content");
        callback();
      } else {
        if (this.ImgText.content) {
          callback();
        } else {
          callback(new Error("请填上传视频"));
          this.$refs.ImgText.validateField("content");
        }
      }
    },
    // 保存
    submit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const data = { ...this.ImgText };
          this.saveLoading = true;
          if (this.upLoadType) {
            data.images = [];
            data.res = JSON.stringify(data.video);
          } else {
            data.video = [];
            data.res = JSON.stringify(data.images);
          }
          data.type = this.upLoadType;
          SaveGraphic(data)
            .then((res) => {
              this.$message.success(res.msg);
              this.openDialog = false;
              this.saveLoading = false;
              this.updateList();
              if (!data.id) {
                this.$refs[formName].resetFields();
              }
            })
            .catch((err) => {
              this.saveLoading = false;
            });
        } else {
          return false;
        }
      });
    },
    // 取消
    cancel(formName) {
      this.openDialog = false;
    },
  },
  watch: {
    GraphicForm: {
      deep: true,
      handler(val) {
        console.log("val", val);
        this.ImgText = {
          ...val,
          video: val.type === 1 ? [...val.res] : [],
          images: val.type === 0 ? [...val.res] : [],
        };
        this.upLoadType = val.type;
        if (!val.id) {
          this.$nextTick(() => {
            this.$refs.ImgText.resetFields();
          });
        }
      },
    },
    upLoadType() {
      this.$refs["ImgText"].clearValidate();
    },
  },
  computed: {
    openDialog: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit("closeDialog", val);
      },
    },
  },
  components: {
    MediaWall,
  },
};
</script>

<style lang="scss" scoped>
.check-post {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

  .form-tip {
    display: flex;
    align-items: center;
    font-size: 12px;
    color: #999;
    line-height: 12px;
    margin-top: 5px;

    span + span {
      margin-left: 4px;
    }
  }
</style>
