<template>
  <div class="content-box">
    <el-dialog
      title="发布人管理"
      :visible="is_show"
      width="700px"
      class="dialog-vertical"
      @close="close()"
      @open="openDialog"
    >
      <el-button
        v-if="isCycOrganization"
        size="small"
        @click="showSelectMember = true"
        >选择{{ '会员' | saasVersionKeyword }}</el-button
      >
      <el-button size="small" @click="showAddPhone = true">添加游客</el-button>
      <el-table
        v-loading="show_loading"
        style="margin-top: 20px"
        class="thead-light"
        stripe
        :data="member_list"
        height="300px"
      >
        <el-table-column label="头像" width="100">
          <template slot-scope="scope">
            <el-image
              style="width: 32px; height: 32px; border-radius: 50%"
              :src="scope.row.avatar"
            ></el-image>
          </template>
        </el-table-column>
        <el-table-column
          v-for="(item, index) in memberTable"
          :key="index"
          :label="item.label"
          :prop="item.prop ? item.prop : '-'"
          :min-width="item.min_width"
        >
          <template slot-scope="scope">
            <span>{{ scope.row[item.prop] | placeholder }}</span>
          </template>
        </el-table-column>
        <el-table-column label="用户类型" width="100">
          <template slot-scope="scope">
            <span>{{ scope.row.title_name ? '会员' : '游客' }}</span>
          </template>
        </el-table-column>
        <el-table-column label="启用" width="100">
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.status"
              :active-value="1"
              :inactive-value="0"
              @change="handleChangeEnable(scope.row.id, $event)"
            ></el-switch>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="80" fixed="right">
          <template slot-scope="scope">
            <el-button
              style="color: #3576ff"
              size="small"
              type="text"
              @click="handleDelUser(scope.row.id)"
              >删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>

      <span slot="footer">
        <el-button @click="close()" size="small">取消</el-button>
        <el-button
          type="primary"
          :loading="show_loading"
          size="small"
          @click="submit()"
          >确定</el-button
        >
      </span>
    </el-dialog>
    <SelectMember
      v-model="showSelectMember"
      :Members="member_list"
      @changeSelect="changeSelect"
    >
    </SelectMember>
    <AddTourist
      :showAddPhone="showAddPhone"
      :activity_id="id"
      @closeAddPhone="closeAddPhone"
    ></AddTourist>
  </div>
</template>
<script>
import SelectMember from '@/modules/common/components/MemberSelector'
import AddTourist from '../ActivityConfig/AddTourist'
import {
  activityConfig,
  checkConfig,
  showConfig,
  addEditorByUser,
  openEnable,
  delUser,
} from '../../api/activity-config/activity-configuration'
export default {
  name: 'EntranceDialog',
  model: {
    prop: 'open',
    event: 'changeOpen',
  },
  props: {
    // 弹窗配置
    open: {
      type: Boolean,
      detault: false,
    },
  },
  components: { SelectMember, AddTourist },
  created() {},
  computed: {
    // 显示框
    is_show: {
      get() {
        return this.$props.open
      },
      set(val) {
        this.$emit('changeOpen', val)
      },
    },
    isCycOrganization() {
      return this.$store.getters.tenant.features.includes('cyc_organization')
    },
  },
  watch: {},

  data() {
    return {
      id: 0,
      form: {},
      rules: {},

      show_loading: false,
      showSelectMember: false,
      showAddPhone: false,
      member_list: [],
      memberTable: [
        { label: '用户名称', prop: 'name', min_width: 100 },
        { label: '会内职务', prop: 'title_name', min_width: 200 },
        { label: '手机号码', prop: 'phone', min_width: 120 },
      ],
    }
  },
  methods: {
    close() {
      this.is_show = false
    },
    submit() {
      this.close()
    },
    getActivityConfig() {
      this.show_loading = true
      activityConfig({ id: this.id }).then((res) => {
        this.configForm = res.data.activity
        this.member_list = res.data.editors
        this.show_loading = false
      })
    },
    changeSelect(data) {
      this.showSelectMember = false
      let arr = []
      data.forEach((e) => {
        arr.push(e.user_id)
      })
      let obj = {
        id: this.id,
        user_ids: arr,
      }
      addEditorByUser(obj).then((res) => {
        this.getActivityConfig()
      })
    },
    closeAddPhone(data) {
      this.showAddPhone = false
      if (data) {
        this.getActivityConfig()
      }
    },
    handleDelUser(id) {
      this.$confirm('确定要删除该发布人？删除后不可恢复', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          delUser({ id }).then((res) => {
            this.getActivityConfig()
          })
        })
        .catch(() => {})
    },
    openDialog() {
      this.id = this.$route.params.id
 

      if (this.id) {
        this.getActivityConfig()
      } else {
        this.id = this.$route.params.id
      }
    },
  },
}
</script>
<style lang="scss" scoped></style>
