<template>
  <div class="section" v-loading="loading">
    <el-button type="primary" @click="AddGraphic">发布图文</el-button>
    <el-button type="primary" @click="clientEntrance">客户端发布入口</el-button>
    <el-button type="primary" @click="setPublisher">发布人管理</el-button>
    <h3>图文列表</h3>
    <el-steps class="imgtext-list" direction="vertical" :active="1">
      <el-step class="list-item" v-for="(Graphic, index) in GraphicList" :key="index">
        <div slot="icon" style="line-height: 0px">
          <p class="side-point"></p>
        </div>
        <div class="item-main" slot="description">
          <div class="item-head">
            <div style="display: flex">
              <el-image class="item-avatar" :src="Graphic.avatar" fit="cover"></el-image>
              <div>
                <p class="nickname" :style="{'line-height': Graphic.title_name?'30px':'60px'}">
                  {{Graphic.nickname}}</p>
                <p class="position_name">{{Graphic.title_name}}</p>
              </div>
            </div>
            <div class="right-side">
              <p class="time">{{Graphic.create_time}}</p>
              <p class="edit cursor-pointer el-icon-edit-outline" @click="EditGraphic(Graphic)"></p>
              <p class="delete cursor-pointer el-icon-delete" @click="DeleteGraphic(Graphic)"></p>
            </div>
          </div>
          <div style="margin-bottom: 36px">
            <div class="item-content">{{Graphic.content}}</div>
            <div class="item-media">
              <div v-if="Graphic.res.length === 1">
                <el-image @click="$previewImage([Graphic.res[0].url])" class="single-img cursor-pointer"
                  fit="cover" :src="fixImageUrl(Graphic.type?Graphic.res[0].thumbnail:Graphic.res[0].url)">
                </el-image>
                <div v-if="Graphic.type === 1" class="video-mask">
                  <p class="play-icon el-icon-caret-right" @click.stop="playVideo(Graphic.res[0])"></p>
                </div>
              </div>
              <div v-if="Graphic.res.length > 1">
                <div class="media-item" v-if="Graphic.type === 0">
                  <el-image v-for="(image, index) in Graphic.res" style="cursor: pointer;"
                    @click="$previewImage([image.url])" class="multi-img" fit="cover"
                    :src="fixImageUrl(image.url)" :key="index">
                  </el-image>
                </div>
                <div class="media-item" v-if="Graphic.type === 1">
                  <div class="multi-video" v-for="(video, index) in Graphic.res" :key="index">
                    <el-image class="multi-img" fit="cover" :src="fixImageUrl(video.thumbnail)">
                    </el-image>
                    <div class="multi-video-mask">
                      <p class="play-icon el-icon-caret-right" @click.stop="playVideo(video)"></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-step>
      <div class="no-result" v-if="!GraphicList.length">暂无数据</div>
    </el-steps>
    <add-graphic v-model="isOpenGraphicDialog" :GraphicForm="GraphicForm" :updateList="updateGraphic">
    </add-graphic>
    <el-image-viewer v-if="showPreview" :on-close="closePreView" :initialIndex="currentIndex"
      :url-list="srcList" />
    <VideoDialog v-model="isOpenPlay" :videoData="VideoData" />
    <!-- <EntranceDialog v-model="showEntranceDialog"></EntranceDialog> -->
    <PublisherDialog v-model="showPublisherDialog"></PublisherDialog>
    <ActivityConfig :showCode="showEntranceDialog" titleName="客户端发布入口" :activity_id="id" :status="1"
      @closeCode="showEntranceDialog = false"></ActivityConfig>
  </div>
</template>

<script>
import {
  GraphicList,
  SaveGraphic,
  DeleteGraphic,
} from "../../api/activity-list/graphic";
import AddGraphic from "../../components/ActivityList/AddGraphic.vue";
import EntranceDialog from "../../components/ActivityList/EntranceDialog.vue";
import PublisherDialog from "../../components/ActivityList/PublisherDialog.vue";
import ActivityConfig from "../../components/ActivityConfig/ActivityConfig.vue";
import VideoDialog from "../../../../base/components/Preview/VideoViewer"; // 视频弹窗
import ElImageViewer from "element-ui/packages/image/src/image-viewer";
export default {
  components: { AddGraphic, VideoDialog, ElImageViewer,EntranceDialog,PublisherDialog,ActivityConfig},
  data() {
    return {
      loading: false,
      id: 0,
      page: 1,
      GraphicList: [],
      //图文表单数据
      GraphicForm: {
        id: 0,
        activity_id: this.$route.params.id,
        content: "",
        images: [],
        video: [],
      },
      srcList: [], // 图片预览数组
      VideoData: {}, // 播放的视频数据
      currentIndex: 0, // 当前预览索引
      getable: true,
      showPreview: false, // 图片预览弹窗
      isOpenPlay: false, // 视频播放弹窗
      isOpenGraphicDialog: false,
      // 客户端发布入口弹窗
      showEntranceDialog:false,
      // 发布人管理
      showPublisherDialog:false,
    };
  },
  methods: {
    //获取图文列表
    getGraphicList() {
      if (!this.getable) return;
      this.loading = true;
      let pageData = {
        id: this.id,
        page: this.page,
      };
      GraphicList(pageData)
        .then((res) => {
          if (this.page === 1) {
            this.GraphicList = res.data;
          } else {
            this.GraphicList = [...this.GraphicList, ...res.data];
          }
          this.getable = res.data.length < 10 ? false : true;
          this.page++;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    // 入口
    clientEntrance(){
      this.showEntranceDialog = true;
    },
    // 发布人管理
    setPublisher() {
      this.showPublisherDialog = true
    },
    //发布图文
    AddGraphic() {
      this.GraphicForm = {
        id: 0,
        activity_id: this.$route.params.id,
        content: "",
        res: [],
        type: 0,
      };
      this.isOpenGraphicDialog = true;
    },
    // 编辑图文
    EditGraphic(row) {
      this.GraphicForm = row;
      this.isOpenGraphicDialog = true;
    },
    //删除图文
    DeleteGraphic(row) {
      this.$msgbox
        .confirm("确定要删除该数据吗？删除后无法恢复。", "提示", {
          type: "error",
        })
        .then((res) => {
          this.loading = true;
          DeleteGraphic({ id: row.id })
            .then((res) => {
              this.$message.success(res.msg);
              this.updateGraphic();
            })
            .catch((err) => {
              this.loading = false;
            });
        })
        .catch((err) => {});
    },
    // 图片预览
    handlePreview(arr, i) {
      if (i) {
        this.currentIndex = i;
      } else {
        this.currentIndex = 0;
      }
      this.srcList = arr;
      this.showPreview = true;
    },
    // 关闭预览
    closePreView() {
      this.showPreview = false;
    },
    // 播放视频
    playVideo(data) {
      this.VideoData = data;
      this.isOpenPlay = true;
    },
    //更新图文列表
    updateGraphic() {
      this.page = 1;
      this.getable = true;
      this.getGraphicList();
    },
  },
  created() {
    this.id = this.$route.params.id;
    this.getGraphicList();
  },
};
</script>

<style lang='scss' scoped>
.section {
  padding: 0 20px 20px;
  min-height: calc(100vh - 280px);

  .imgtext-list {
    width: 80%;
    min-width: 600px;
    min-height: calc(100vh - 380px);
    padding: 28px 40px 30px 20px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    border: 1px solid #e4e7ed;

    .list-item {
      position: relative;
      ::v-deep.el-step__icon.is-text {
        height: 8px;
        border: none;
        background-color: transparent;
      }

      ::v-deep.el-step__head {
        top: 20px;
      }

      ::v-deep.el-step__description {
        padding: 0;
      }

      ::v-deep.el-step__line {
        top: 10px;
        bottom: -10px;
      }

      .item-head {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-bottom: 8px;
        .item-avatar {
          margin-right: 20px;
          width: 60px;
          height: 60px;
          border-radius: 50%;
        }
      }

      .side-point {
        display: inline-block;
        background-color: #3576ff;
        width: 8px;
        height: 8px;
        margin-right: 0;
        border-radius: 50%;
        vertical-align: middle;
      }

      .nickname {
        font-size: 18px;
        color: #303133;
      }

      .position_name {
        font-size: 15px;
        color: #67757c;
        line-height: 30px;
      }

      .right-side {
        display: flex;
        color: #909399;

        .time {
          font-size: 14px;
          font-weight: normal;
        }

        p + p {
          font-size: 20px;
          margin-left: 15px;
        }

        .edit:hover,
        .delete:hover {
          color: #c0c4cc !important;
        }
      }

      .item-content {
        font-size: 16px;
        color: rgba(48, 49, 51, 1);
        line-height: 24px;
        margin-bottom: 16px;
      }

      .item-media {
        position: relative;
        .single-img {
          width: 264px;
          height: 264px;
        }

        .video-mask {
          background-color: rgba(0, 0, 0, 0.5);
          position: absolute;
          top: 0;
          left: 0;
          width: 264px;
          height: 264px;
          z-index: 2;
          text-align: center;
          .play-icon {
            cursor: pointer;
            font-size: 55px;
            color: #fff;
            line-height: 264px;
          }
        }

        .media-item {
          display: flex;
          flex-wrap: wrap;
          margin-bottom: 23px;

          .multi-img {
            margin-right: 17px;
            margin-bottom: 17px;
            width: 165px;
            height: 165px;
          }

          .multi-video {
            position: relative;
            .multi-video-mask {
              background-color: rgba(0, 0, 0, 0.5);
              position: absolute;
              top: 0;
              left: 0;
              width: 165px;
              height: 165px;
              z-index: 2;
              text-align: center;
              .play-icon {
                cursor: pointer;
                font-size: 33px;
                color: #fff;
                line-height: 165px;
              }
            }
          }
        }
      }
    }
  }
}
h3 {
  padding: 20px;
}

.no-result {
  margin: auto;
  color: #909399;
  font-size: 14px;
}
</style>
