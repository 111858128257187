import { render, staticRenderFns } from "./PublisherDialog.vue?vue&type=template&id=76449d60&scoped=true&"
import script from "./PublisherDialog.vue?vue&type=script&lang=js&"
export * from "./PublisherDialog.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "76449d60",
  null
  
)

export default component.exports