import api from "@/base/utils/request";

// 获取图文列表
export const GraphicList = data => {
  return api({
    url: "/admin/cyc_activity/activity_graphic/timelines",
    method: "post",
    data
  });
};

// 保存图文
export const SaveGraphic = data => {
  return api({
    url: "/admin/cyc_activity/activity_graphic/publishedGraphic",
    method: "post",
    data
  });
};

//删除图文
export const DeleteGraphic = data => {
  return api({
    url: "/admin/cyc_activity/activity_graphic/del",
    method: "post",
    data
  });
};