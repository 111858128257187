<template>
  <el-dialog
    title="添加游客为发布人员"
    :visible="showAddPhone"
    width="540px"
    class="dialog-vertical"
    @close="cancel(0)"
  >
    <el-form
      label-width="68px"
      size="medium"
      style="margin: 20px 0"
      @submit.native.prevent
    >
      <div v-for="(item, index) in phoneLength" :key="index">
        <!-- <div style="color: rgb(48, 49, 51); font-size: 14px">
          发布人{{ index + 1 }}
        </div> -->
        <div class="modular">
          <p class="modular-title">发布人{{ index + 1 }}</p>
          <el-form-item style="margin-bottom: 28px" label="手机号码：">
            <div class="flex-box">
              <el-input
                v-model="phoneList[index]"
                style="width: 295px"
                placeholder="输入手机号码"
              ></el-input>
              <el-button
                class="del-btn"
                size="medium"
                type="danger"
                plain
                @click="handleDelLength(index)"
                >删除
              </el-button>
            </div>
          </el-form-item>
        </div>
      </div>
      <el-form-item v-if="phoneLength.length < 3">
        <el-button
          class="add-btn"
          type="primary"
          size="medium"
          plain
          @click="handleAddLength"
          >添加
        </el-button>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button size="small" @click="cancel(0)">取消</el-button>
      <el-button size="small" type="primary" @click="handleAddPhone"
        >确定</el-button
      >
    </template>
  </el-dialog>
</template>

<script>
import { addEditorByPhone } from '../../api/activity-config/activity-configuration'
export default {
  name: 'AddTourist',
  props: ['showAddPhone', 'activity_id'],
  data() {
    return {
      phoneLength: [1],
      phoneList: [''],
    }
  },
  methods: {
    handleAddLength() {
      this.phoneLength.push(1)
      this.phoneList.push('')
    },
    handleDelLength(index) {
      this.phoneLength.splice(index, 1)
      this.phoneList.splice(index, 1)
    },
    handleAddPhone() {
      for (let i = 0; i < this.phoneList.length; i++) {
        console.log(this.phoneList[i])
        if (
          !/^([1]\d{10}|([\(（]?0[0-9]{2,3}[）\)]?[-]?)?([2-9][0-9]{6,7})+(\-[0-9]{1,4})?)$/.test(
            this.phoneList[i]
          )
        ) {
          this.$message({
            message: '发布人 ' + (i + 1) + ' 请输入正确的电话号码',
          })
          return
        }
      }
      let data = {
        id: this.activity_id,
        phones: this.phoneList,
      }
      addEditorByPhone(data).then((res) => {
        this.cancel(1)
      })
    },
    cancel(data) {
      this.$emit('closeAddPhone', data)
    },
  },
}
</script>

<style lang="scss" scoped>
.flex-box {
  display: flex;
  align-items: center;
}
.el-form-item {
  .el-button {
    background-color: #fff;
    &:hover {
      background-color: #fff;
    }
  }
}
.del-btn {
  // width: 80%;
  margin-left: 12px;
  color: #f56c6c !important;
  border: 1px dashed #f56c6c;
}
.add-btn {
  width: 295px;
  color: #3576ff !important;
  border: 1px dashed #3576ff;
}
</style>
