import api from '@/base/utils/request';

// 获取活动配置
export const activityConfig = data => {
    return api({
        url: '/admin/cyc_activity/activity_config/config',
        method: 'post',
        data
    });
};
//签到二维码
export const activityQrCode = data => {
    return api({
        url: '/admin/cyc_activity/activity_config/QrCode',
        method: 'post',
        data
    });
};
//审核配置
export const checkConfig = data => {
    return api({
        url: '/admin/cyc_activity/activity_config/checkConfig',
        method: 'post',
        data
    });
};
//展示配置
export const showConfig = data => {
    return api({
        url: '/admin/cyc_activity/activity_config/showConfig',
        method: 'post',
        data
    });
};
//添加会内人员为发布人
export const addEditorByUser = data => {
    return api({
        url: '/admin/cyc_activity/activity_config/addEditorByUser',
        method: 'post',
        data
    });
};
//是否启用发布人员
export const openEnable = data => {
    return api({
        url: '/admin/cyc_activity/activity_config/enable',
        method: 'post',
        data
    });
};
//删除发布人
export const delUser = data => {
    return api({
        url: '/admin/cyc_activity/activity_config/del',
        method: 'post',
        data
    });
};
//添加游客为发布人
export const addEditorByPhone = data => {
    return api({
        url: '/admin/cyc_activity/activity_config/addEditorByPhone',
        method: 'post',
        data
    });
};
//获取链接
export const codeLink = data => {
    return api({
        url: '/admin/cyc_activity/activity_config/link',
        method: 'post',
        data
    });
};